body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #fff !important; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
}

.MuiDataGrid-menuIcon {
  display: none !important;
}

.MuiDataGrid-cell {
  cursor: pointer !important;
}

.MuiDataGrid-root.MuiDataGrid-autoHeight {
  background-color: #fff !important;
}

.MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded {
  box-shadow: none;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 5px;
}

.MuiInput-underline:before {
  display: none;
}

.MuiButton-containedPrimary,
.MuiButton-fullWidth,
.MuiButton-sizeLarge {
  height: 56px;
}
